import React, {useState, useEffect, Fragment} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ListItemButton from '@mui/material/ListItemButton';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';


import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const Loans = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);

    const initialLogin = {
        amount: '',
        description: '',
        loan_type: ''
    }
    
    
    const [error, setErros] = useState('');
    const [loanForm, setloanForm] = useState(initialLogin);
    const loanPlans = useSelector((state) => state.plans.loanPlans);
    const loans = useSelector((state) => state.history.loans);

    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        return time;
      }

    const onChange = (e) =>
    setloanForm({ ...loanForm, [e.target.name]: e.target.value });
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    
    const initialImage = {
        image: ''
    }
    const [FrontImage, setImage] = useState(initialImage);
    const [source, setSource] = useState('');

    const  onFileChange = (event) => { 
        setImage({...FrontImage, image: event.target.files[0]});
      }; 


      const initialBackImage = {
        image: ''
    }
    const [BackImage, setBackImage] = useState(initialBackImage);
    const  onBackFileChange = (event) => { 
        setBackImage({...BackImage, image: event.target.files[0]});
      }; 

      const bankDetails = {
        image: ''
    }
    const [bankImage, setBankImage] = useState(bankDetails);
    const  onBankFileChange = (event) => { 
        setBankImage({...bankImage, image: event.target.files[0]});
      }; 

      const certificateDetails = {
        image: ''
    }
    const [certificateImage, setCertificateImage] = useState(certificateDetails);
    const  onCertificateFileChange = (event) => { 
        setCertificateImage({...certificateImage, image: event.target.files[0]});
      }; 



    const { isLoading: isSendingRequest, mutate: postloan } = useMutation(

        async () => {
            const formData = new FormData();
            formData.append('amount', loanForm.amount);
            formData.append('loan_type', source);
            formData.append('description', loanForm.description);
            formData.append('house_image', FrontImage.image);
            formData.append('income_image', BackImage.image);
            formData.append('bank_image', bankImage.image);
            formData.append('cert_image', certificateImage.image);
            
            return await apiClient.post(`/api/store-loan`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                  },
              });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);

            setTimeout(() => {
                window.location.reload();
            }, 2000);
          },
          onError: (err) => {   

             if (err.response?.data.length) {
                let myerror = err.response?.data || err;         
            setErros(myerror.errors)     
            const event2 = new Event('processed');
            window.dispatchEvent(event2);          
            }else{
                let errorMessage = err.response?.data.message || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                const event2 = new Event('processed');
            window.dispatchEvent(event2);
            }    
          },
        }
      );

      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postloan();
      } 

      const [curetnPlan, setCurrentPlan] = useState(null);

      const setSelectedPlan = (planId) => {
        loanPlans.forEach(plan => {
            if (parseInt(plan.id) === parseInt(planId)) {
                setCurrentPlan(plan);
                setSource(planId);
            }
          });
      }

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                    APPLY FOR LOAN
                    </Typography>
                    <Typography mb={4} sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    Note: kindly note that only users who have completed their KYC are eligible to apply.
                    </Typography>

                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <FormControl fullWidth={true} sx={{  minWidth: 50, marginBottom: 2 }}>
                            <InputLabel htmlFor="amount">Amount (USDT)</InputLabel>
                            {(error !== '' && error.amount) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="amount"
                            type={'number'}
                            name="amount"
                            label="Amount (USDT)"
                            helperText={error.amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="amount"
                            type={'number'}
                            name="amount"
                            label="Amount (USDT)"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.amount}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{marginLeft: '5px', minWidth: 50, marginBottom: 2}}>
                            <InputLabel htmlFor="loan_type">Repayment Cycle</InputLabel>
                            {(error !== '' && error.loan_type) ?
                                <Select
                                    error
                                    onChange={(e) => setSelectedPlan(e.target.value)}
                                    labelId="loan_type"
                                    id="loan_type"
                                    name="loan_type"
                                    label={`Repayment Cycle`}
                                    helperText={error.loan_type}
                                    >
                                    <MenuItem selected value=''>
                                    Repayment Cycle
                                    </MenuItem> 
                                    {loanPlans.length > 0 &&
                                    loanPlans.map(plan =>(
                                    <MenuItem key={plan.id} selected value={plan.id}>
                                    {plan.duration}
                                    </MenuItem> 
                                    ))
                                    }             
                                    
                                </Select>
                            :
                                <Select
                                    onChange={(e) => setSelectedPlan(e.target.value)}
                                    labelId="loan_type"
                                    name="loan_type"
                                    id="loan_type"
                                    label={`Repayment Cycle`}
                                    >
                                    <MenuItem selected value=''>
                                    Repayment Cycle
                                    </MenuItem> 
                                    {loanPlans.length > 0 &&
                                    loanPlans.map(plan =>(
                                    <MenuItem key={plan.id} selected value={plan.id}>
                                    {plan.duration}
                                    </MenuItem> 
                                    ))
                                    }  
                                </Select>
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.loan_type}
                            </Typography>

                        </FormControl>

                        
                    </Box>

                    <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                        <CardContent>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color='success' />
                                    </ListItemIcon>
                                    <ListItemText secondary="Daily Interest Rate" primary={curetnPlan !== null ? curetnPlan.interest+'%': '...%'}/>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color='success' />
                                    </ListItemIcon>
                                    <ListItemText secondary="Repayment Method" primary="Due Date"  />
                                    </ListItemButton>
                                </ListItem>
                                {console.log(curetnPlan)}
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color='success' />
                                    </ListItemIcon>
                                    <ListItemText secondary="Interest" primary={curetnPlan !== null && loanForm.amount !== '' ? formatPrice(parseInt((curetnPlan.interest/100)*loanForm.amount)): '...'}  />
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color='success' />
                                    </ListItemIcon>
                                    <ListItemText secondary="Handling Fee Rate" primary={curetnPlan !== null ? curetnPlan.handling_fee+'%': '...%'}  />
                                    </ListItemButton>
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color='success' />
                                    </ListItemIcon>
                                    <ListItemText secondary="Lending Institution" primary="Assist Loan"  />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card> 

                
                
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        
                      
                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            House Info
                        </Typography>
                        <Box>
                        {FrontImage.image !== '' &&
                            <img style={{width: "100px", height: "auto"}} src={URL.createObjectURL(FrontImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.front_document) ?

                            <OutlinedInput
                            onChange={onFileChange}
                            error
                            accept="image/*"
                            id="front_document"
                            type='file'
                            name="front_document"
                            helperText={error.front_document}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onFileChange}
                            id="front_document"
                            type='file'
                            name="front_document"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.front_document}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Proof of Income
                        </Typography>
                        <Box>
                        {BackImage.image !== '' &&
                            <img style={{width: "100px", height: "auto"}} src={URL.createObjectURL(BackImage.image)} alt="product"/>
                        }
                        </Box>

                        {(error !== '' && error.back_document) ?

                            <OutlinedInput
                            onChange={onBackFileChange}
                            error
                            accept="image/*"
                            id="back_document"
                            type='file'
                            name="back_document"
                            helperText={error.back_document}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onBackFileChange}
                            id="back_document"
                            type='file'
                            name="back_document"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.back_document}
                            </Typography>
                        </FormControl>

                        </Box>

                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        
                      
                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Bank Details
                        </Typography>
                        <Box>
                        {bankImage.image !== '' &&
                            <img style={{width: "100px", height: "auto"}} src={URL.createObjectURL(bankImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.bank_details) ?

                            <OutlinedInput
                            onChange={onBankFileChange}
                            error
                            accept="image/*"
                            id="bank_details"
                            type='file'
                            name="bank_details"
                            helperText={error.bank_details}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onBankFileChange}
                            id="bank_details"
                            type='file'
                            name="bank_details"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.bank_details}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Certificate Image
                        </Typography>
                        <Box>
                        {certificateImage.image !== '' &&
                            <img style={{width: "100px", height: "auto"}} src={URL.createObjectURL(certificateImage.image)} alt="product"/>
                        }
                        </Box>

                        {(error !== '' && error.certificate_image) ?

                            <OutlinedInput
                            onChange={onCertificateFileChange}
                            error
                            accept="image/*"
                            id="certificate_image"
                            type='file'
                            name="certificate_image"
                            helperText={error.certificate_image}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onCertificateFileChange}
                            id="certificate_image"
                            type='file'
                            name="certificate_image"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.certificate_image}
                            </Typography>
                        </FormControl>

                        </Box>

                        <FormControlLabel
                            control={
                            <Checkbox  name="antoine" />
                            }
                            label="I accept loan terms and conditions"
                        />

                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Sending...' : 'Send Request'}</Button>
                    </CardActions>
                </Card>
            </Grid>
                {console.log(loans)}
            <Grid item xs={12} md={4}>
            {loans.length > 0 &&
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        subheader={<ListSubheader>Loan History</ListSubheader>}
        >


        {loans.map(loan => (
        <Fragment key={loan.id}>
        <ListItem sx={{cursor: 'pointer'}} alignItems="flex-start">
        <ListItemAvatar>
            
            {loan.status === 'pending' &&
            <Avatar  sx={{ bgcolor: '#f7a21d' }}>
            <PendingIcon/>
            </Avatar>
            }
            
            {loan.status === 'approved' &&
            <Avatar  sx={{ bgcolor: '#37dc8a' }}>
            <CheckCircleIcon/>
            </Avatar>
            }

            {loan.status === 'declined' &&
            <Avatar  sx={{ bgcolor: '#dd0a22' }}>
            <DangerousIcon/>
            </Avatar>
            
            }

            {loan.status === 'completed' &&
            <Avatar  sx={{ bgcolor: '#ccc6c6' }}>
            <DoneAllIcon/>
            </Avatar>
            
            }
            
            
        </ListItemAvatar>

        <ListItemText
            primary={`${formatPrice(loan.amount)}`}
            secondary={
            <React.Fragment>
                <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
                >
                {loan.interest_rate}%
                </Typography>
            </React.Fragment>
            }
        />
        

            <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-even', alignContent: 'center', alignItems: 'right'}}>
                <Typography color={loan.status === 'pending'? 'secondary': 'primary.success'}  edge="end">
                {loan.status}
                </Typography>

                <Typography  edge="end">
                {timeConverter(loan.due_date)}
                </Typography>
            </Box>

        
        </ListItem>
        <Divider  component="li" variant="inset" />
        </Fragment>
        ))}
        </List>
        }
            </Grid>
        </Grid>
    </div>
  )
}

export default Loans
