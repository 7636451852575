import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';

export default function ListLoans() {
    const [loans, setloans] = useState(null);

    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        return time;
      }

    const { isLoading: isLoadingloans, refetch: getloans } = useQuery(
        "load-loans",
        async () => {
          return await apiClient.get("/api/loans");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setloans(res.data.loans);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );
    
      useEffect(() => {
        getloans() 
        if (isLoadingloans) {
            const event3 = new Event('processing');
            window.dispatchEvent(event3);
        }
      }, []);

  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        
        {isLoadingloans? 'Loading loans...' : 'List of loans'}
      </Typography>
    <TableContainer component={Paper}>
        
      {loans !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        <TableCell>User Address</TableCell>
        <TableCell align="right">Amount</TableCell>
        <TableCell align="right">Interest Rate</TableCell>
        <TableCell align="right">Due Date</TableCell>
        <TableCell align="right">House Image</TableCell>
        <TableCell align="right">Income Image</TableCell>
        <TableCell align="right">Bank Image</TableCell>
        <TableCell align="right">Certificate Image</TableCell>

        <TableCell align="right">View</TableCell>
          <TableCell align="right">Profile</TableCell>
          <TableCell align="right">Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loans.map((loan) => (
          <TableRow
            key={loan.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            {loan.user.address}
            </TableCell>
            <TableCell align="right">{loan.amount}</TableCell>
            <TableCell align="right">{loan.interest_rate}</TableCell>
            <TableCell align="right">{timeConverter(loan.due_date)}</TableCell>

            <TableCell align="right"><img style={{height: '50px'}} src={loan.house_image}/></TableCell>
            <TableCell align="right"><img style={{height: '50px'}} src={loan.income_image}/></TableCell>
            <TableCell align="right"><img style={{height: '50px'}} src={loan.bank_image}/></TableCell>
            <TableCell align="right"><img style={{height: '50px'}} src={loan.cert_image}/></TableCell>
            
            
            <TableCell align="right"><Button component={RouterLink} to={`/user-dashboard/${loan.id}`} startIcon={<EditIcon />}>View loan</Button></TableCell>
            <TableCell align="right"><Avatar alt={loan.user.name} src={loan.user.profile} /></TableCell>
            <TableCell align="right">{getDate(loan.created_at)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>
    </div>
  );
}