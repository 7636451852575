import React from 'react'
import CoinMarket from './CoinMarket';
import Cards from '../Cards/Cards';
import MobileMenu from './MobileMenu';

const Index = () => {
  return (
    <>
    {/* <BalanceCard/> */}
    
    {/* <PyramidData/> */}
    
    <Cards/>
    <MobileMenu/>
    <CoinMarket/>
    </>
  )
}

export default Index
