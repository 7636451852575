import React, {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setPlans, setTradingPlans, setLoanPlans } from './planSlice';

export const LoadPlans = () => {
  const dispatch = useDispatch();
    const { isLoading: isLoadingProducts, refetch: getProducts } = useQuery(
        "plans",
        async () => {
          return await apiClient.get("/api/investment-plans");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
              dispatch(setPlans(res.data.plans));
              dispatch(setTradingPlans(res.data.trading_plans));
              dispatch(setLoanPlans(res.data.loan_plans));
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        getProducts();
      }, []);
 
}

export default LoadPlans
