import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    plans: JSON.parse(localStorage.getItem('plans')) || [],
    tradingPlans: JSON.parse(localStorage.getItem('tradingPlans')) || [],
    loanPlans: JSON.parse(localStorage.getItem('loanPlans')) || [],
}

export const plansSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        setPlans: (state, action) =>{
            state.plans = action.payload;  
            localStorage.setItem('plans', JSON.stringify(state.plans));
        } ,
        
        setTradingPlans: (state, action) =>{
            state.tradingPlans = action.payload;  
            localStorage.setItem('tradingPlans', JSON.stringify(state.tradingPlans));
        } ,

        setLoanPlans: (state, action) =>{
            state.loanPlans = action.payload;  
            localStorage.setItem('loanPlans', JSON.stringify(state.loanPlans));
        } 
    }
})

export const {setPlans, setTradingPlans, setLoanPlans} = plansSlice.actions;
export default plansSlice.reducer;