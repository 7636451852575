import React, {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import {setWithdrawals, setWallets, setLoans, setInvestments, setDepayTransactions} from './hitorySlice';
import { setAuthStatus } from '../Auth/authSlice';
export const AccountHistory = () => {

  const dispatch = useDispatch();

    const { isLoading: isLoadingHistory, refetch: getHistory } = useQuery(
        "charts-analysis",
        async () => {
          return await apiClient.get("/api/history");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                dispatch(setWithdrawals(res.data.withdrawals))
                dispatch(setWallets(res.data.wallets))
                dispatch(setInvestments(res.data.investments))
                dispatch(setLoans(res.data.loans))
                dispatch(setDepayTransactions(res.data.depay_transactions))
            }
          },
          onError: (err) => {
            if (err.response.status === 401) {
              dispatch(setAuthStatus('unauthenticated'));
                localStorage.removeItem('user');
                localStorage.removeItem('fundings');
                localStorage.setItem('funding-balance', 0);
                localStorage.setItem('subscriptionBalance', 0);
                localStorage.removeItem('subscriptions');
                localStorage.removeItem('notifications');
                localStorage.removeItem('currentRole');
                
                window.ethereum.selectedAddress = null;
                localStorage.removeItem('auth-token')
                const event = new Event('userIsLoggedOut');
                window.dispatchEvent(event);
                window.location.href='/';
            }
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        getHistory();
      }, []);
 
}

export default AccountHistory
